export const AccomodationDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "AccomodationDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccomodationDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const AccomodationDto = {
    type: {
        name: "Composite",
        className: "AccomodationDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            orderNumberDate: {
                serializedName: "orderNumberDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerCompanyId: {
                serializedName: "providerCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            providerCompany: {
                serializedName: "providerCompany",
                type: {
                    name: "Composite",
                    className: "ProviderCompanyDbEntity"
                }
            },
            city: {
                serializedName: "city",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            checkIn: {
                serializedName: "checkIn",
                type: {
                    name: "DateTime"
                }
            },
            checkOut: {
                serializedName: "checkOut",
                type: {
                    name: "DateTime"
                }
            },
            costPerDay: {
                serializedName: "costPerDay",
                type: {
                    name: "Number"
                }
            },
            days: {
                serializedName: "days",
                type: {
                    name: "Number"
                }
            },
            voucherId: {
                serializedName: "voucherId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            voucher: {
                serializedName: "voucher",
                type: {
                    name: "Composite",
                    className: "VoucherEntityDb"
                }
            },
            invoice: {
                serializedName: "invoice",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            vatAward: {
                serializedName: "vatAward",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderCompanyDbEntity = {
    type: {
        name: "Composite",
        className: "ProviderCompanyDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            inn: {
                serializedName: "inn",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            accomodations: {
                serializedName: "accomodations",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccomodationDbEntity"
                        }
                    }
                }
            },
            carriages: {
                serializedName: "carriages",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CarriageDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const AccomodationDbEntity = {
    type: {
        name: "Composite",
        className: "AccomodationDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            claim: {
                serializedName: "claim",
                type: {
                    name: "Composite",
                    className: "ClaimClaimsAreaDbEntity"
                }
            },
            orderNumberDate: {
                serializedName: "orderNumberDate",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerCompanyId: {
                serializedName: "providerCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            providerCompany: {
                serializedName: "providerCompany",
                type: {
                    name: "Composite",
                    className: "ProviderCompanyDbEntity"
                }
            },
            city: {
                serializedName: "city",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            checkIn: {
                serializedName: "checkIn",
                type: {
                    name: "DateTime"
                }
            },
            checkOut: {
                serializedName: "checkOut",
                type: {
                    name: "DateTime"
                }
            },
            days: {
                serializedName: "days",
                type: {
                    name: "Number"
                }
            },
            costPerDay: {
                serializedName: "costPerDay",
                type: {
                    name: "Number"
                }
            },
            voucherId: {
                serializedName: "voucherId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            voucher: {
                serializedName: "voucher",
                type: {
                    name: "Composite",
                    className: "VoucherEntityDb"
                }
            },
            invoice: {
                serializedName: "invoice",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            vatAward: {
                serializedName: "vatAward",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimClaimsAreaDbEntity = {
    type: {
        name: "Composite",
        className: "ClaimClaimsAreaDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            employeeId: {
                serializedName: "employeeId",
                type: {
                    name: "Uuid"
                }
            },
            employee: {
                serializedName: "employee",
                type: {
                    name: "Composite",
                    className: "EmployeeClaimsAreaDbEntity"
                }
            },
            compamyId: {
                serializedName: "compamyId",
                type: {
                    name: "Uuid"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "Composite",
                    className: "CompanyClaimsAreaDbEntity"
                }
            },
            totalPrice: {
                serializedName: "totalPrice",
                type: {
                    name: "Number"
                }
            },
            beginDate: {
                serializedName: "beginDate",
                type: {
                    name: "DateTime"
                }
            },
            endDate: {
                serializedName: "endDate",
                type: {
                    name: "DateTime"
                }
            },
            accomodations: {
                serializedName: "accomodations",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccomodationDbEntity"
                        }
                    }
                }
            },
            carriages: {
                serializedName: "carriages",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CarriageDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const EmployeeClaimsAreaDbEntity = {
    type: {
        name: "Composite",
        className: "EmployeeClaimsAreaDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            position: {
                serializedName: "position",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            codeNumber: {
                serializedName: "codeNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claims: {
                serializedName: "claims",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimClaimsAreaDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const CompanyClaimsAreaDbEntity = {
    type: {
        name: "Composite",
        className: "CompanyClaimsAreaDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claims: {
                serializedName: "claims",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimClaimsAreaDbEntity"
                        }
                    }
                }
            }
        }
    }
};
export const CarriageDbEntity = {
    type: {
        name: "Composite",
        className: "CarriageDbEntity",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            claim: {
                serializedName: "claim",
                type: {
                    name: "Composite",
                    className: "ClaimClaimsAreaDbEntity"
                }
            },
            ticketNumber: {
                serializedName: "ticketNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketDate: {
                serializedName: "ticketDate",
                type: {
                    name: "DateTime"
                }
            },
            providerCompanyId: {
                serializedName: "providerCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            providerCompany: {
                serializedName: "providerCompany",
                type: {
                    name: "Composite",
                    className: "ProviderCompanyDbEntity"
                }
            },
            route: {
                serializedName: "route",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketPrice: {
                serializedName: "ticketPrice",
                type: {
                    name: "Number"
                }
            },
            vatTicket: {
                serializedName: "vatTicket",
                type: {
                    name: "Number"
                }
            },
            surcharges: {
                serializedName: "surcharges",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fine: {
                serializedName: "fine",
                type: {
                    name: "Number"
                }
            },
            amountUnusedTicket: {
                serializedName: "amountUnusedTicket",
                type: {
                    name: "Number"
                }
            },
            vatFine: {
                serializedName: "vatFine",
                type: {
                    name: "Number"
                }
            },
            serviceFee: {
                serializedName: "serviceFee",
                type: {
                    name: "Number"
                }
            },
            vatService: {
                serializedName: "vatService",
                type: {
                    name: "Number"
                }
            },
            award: {
                serializedName: "award",
                type: {
                    name: "Number"
                }
            },
            paymentOrder: {
                serializedName: "paymentOrder",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            carriageType: {
                serializedName: "carriageType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const VoucherEntityDb = {
    type: {
        name: "Composite",
        className: "VoucherEntityDb",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            accomodationId: {
                serializedName: "accomodationId",
                type: {
                    name: "Uuid"
                }
            },
            accomodation: {
                serializedName: "accomodation",
                type: {
                    name: "Composite",
                    className: "AccomodationDbEntity"
                }
            },
            voucherNumber: {
                serializedName: "voucherNumber",
                type: {
                    name: "Number"
                }
            },
            voucherPrice: {
                serializedName: "voucherPrice",
                type: {
                    name: "Number"
                }
            },
            vatVoucherPrice: {
                serializedName: "vatVoucherPrice",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CarriageDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CarriageDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CarriageDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CarriageDto = {
    type: {
        name: "Composite",
        className: "CarriageDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            ticketNumber: {
                serializedName: "ticketNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketDate: {
                serializedName: "ticketDate",
                type: {
                    name: "DateTime"
                }
            },
            providerCompanyId: {
                serializedName: "providerCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            providerCompany: {
                serializedName: "providerCompany",
                type: {
                    name: "Composite",
                    className: "ProviderCompanyDto"
                }
            },
            route: {
                serializedName: "route",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketPrice: {
                serializedName: "ticketPrice",
                type: {
                    name: "Number"
                }
            },
            vatTicket: {
                serializedName: "vatTicket",
                type: {
                    name: "Number"
                }
            },
            surcharges: {
                serializedName: "surcharges",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fine: {
                serializedName: "fine",
                type: {
                    name: "Number"
                }
            },
            amountUnusedTicket: {
                serializedName: "amountUnusedTicket",
                type: {
                    name: "Number"
                }
            },
            vatFine: {
                serializedName: "vatFine",
                type: {
                    name: "Number"
                }
            },
            serviceFee: {
                serializedName: "serviceFee",
                type: {
                    name: "Number"
                }
            },
            vatService: {
                serializedName: "vatService",
                type: {
                    name: "Number"
                }
            },
            award: {
                serializedName: "award",
                type: {
                    name: "Number"
                }
            },
            paymentOrder: {
                serializedName: "paymentOrder",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            carriageType: {
                serializedName: "carriageType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ProviderCompanyDto = {
    type: {
        name: "Composite",
        className: "ProviderCompanyDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            inn: {
                serializedName: "inn",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDto = {
    type: {
        name: "Composite",
        className: "ClaimDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            createDateTime: {
                serializedName: "createDateTime",
                type: {
                    name: "DateTime"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            codeNumber: {
                serializedName: "codeNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            beginDate: {
                serializedName: "beginDate",
                type: {
                    name: "DateTime"
                }
            },
            endDate: {
                serializedName: "endDate",
                type: {
                    name: "DateTime"
                }
            },
            totalPrice: {
                serializedName: "totalPrice",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDetailDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDetailDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimDetailDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDetailDto = {
    type: {
        name: "Composite",
        className: "ClaimDetailDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            employee: {
                serializedName: "employee",
                type: {
                    name: "Composite",
                    className: "EmployeeDto"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "Composite",
                    className: "CompanyDto"
                }
            },
            accomodations: {
                serializedName: "accomodations",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AccomodationDto"
                        }
                    }
                }
            },
            carriages: {
                serializedName: "carriages",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CarriageDto"
                        }
                    }
                }
            }
        }
    }
};
export const EmployeeDto = {
    type: {
        name: "Composite",
        className: "EmployeeDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            position: {
                serializedName: "position",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            codeNumber: {
                serializedName: "codeNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const CompanyDto = {
    type: {
        name: "Composite",
        className: "CompanyDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimDetailDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimDetailDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimDetailDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompanyDtoApiResponse = {
    type: {
        name: "Composite",
        className: "CompanyDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "CompanyDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const CompanyDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "CompanyDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CompanyDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimMessageDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimMessageDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ClaimMessageDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimMessageDto = {
    type: {
        name: "Composite",
        className: "ClaimMessageDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            userName: {
                serializedName: "userName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            content: {
                serializedName: "content",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            messageSourceId: {
                serializedName: "messageSourceId",
                type: {
                    name: "Number"
                }
            },
            messageStatusId: {
                serializedName: "messageStatusId",
                type: {
                    name: "Number"
                }
            },
            departmentMessageTypeId: {
                serializedName: "departmentMessageTypeId",
                type: {
                    name: "Number"
                }
            },
            originalDepartmentMessageTypeId: {
                serializedName: "originalDepartmentMessageTypeId",
                type: {
                    name: "Number"
                }
            },
            managerId: {
                serializedName: "managerId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            managerName: {
                serializedName: "managerName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            senderUserId: {
                serializedName: "senderUserId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            version: {
                serializedName: "version",
                type: {
                    name: "Number"
                }
            },
            senderManagerCbDepartmentId: {
                serializedName: "senderManagerCbDepartmentId",
                nullable: true,
                type: {
                    name: "Uuid"
                }
            },
            visibilityGroupMaskId: {
                serializedName: "visibilityGroupMaskId",
                type: {
                    name: "Number"
                }
            },
            fromTourOperatorDate: {
                serializedName: "fromTourOperatorDate",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            translateToTourOperator: {
                serializedName: "translateToTourOperator",
                type: {
                    name: "Boolean"
                }
            },
            files: {
                serializedName: "files",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourBookingFileDto"
                        }
                    }
                }
            }
        }
    }
};
export const TourBookingFileDto = {
    type: {
        name: "Composite",
        className: "TourBookingFileDto",
        modelProperties: {
            fileStorageId: {
                serializedName: "fileStorageId",
                type: {
                    name: "Uuid"
                }
            },
            fileTypeId: {
                serializedName: "fileTypeId",
                type: {
                    name: "Number"
                }
            },
            fileUploadSource: {
                serializedName: "fileUploadSource",
                type: {
                    name: "Number"
                }
            },
            fileType: {
                serializedName: "fileType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            createDate: {
                serializedName: "createDate",
                type: {
                    name: "DateTime"
                }
            },
            fileAttributeExpiresDateTimeUtc: {
                serializedName: "fileAttributeExpiresDateTimeUtc",
                nullable: true,
                type: {
                    name: "DateTime"
                }
            },
            fileAttributeRequireAuthorization: {
                serializedName: "fileAttributeRequireAuthorization",
                type: {
                    name: "Boolean"
                }
            },
            fileAttributeFileSizeByte: {
                serializedName: "fileAttributeFileSizeByte",
                nullable: true,
                type: {
                    name: "Number"
                }
            },
            fileLink: {
                serializedName: "fileLink",
                nullable: true,
                type: {
                    name: "String"
                }
            }
        }
    }
};
export const ClaimMessageDtoApiResponse = {
    type: {
        name: "Composite",
        className: "ClaimMessageDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "ClaimMessageDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ClaimMessageCreateRequest = {
    type: {
        name: "Composite",
        className: "ClaimMessageCreateRequest",
        modelProperties: {
            claimId: {
                serializedName: "claimId",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            messageContent: {
                serializedName: "messageContent",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            tourBookingFiles: {
                serializedName: "tourBookingFiles",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TourBookingFileDto"
                        }
                    }
                }
            }
        }
    }
};
export const OkResponseApiResponse = {
    type: {
        name: "Composite",
        className: "OkResponseApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "OkResponse"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const OkResponse = {
    type: {
        name: "Composite",
        className: "OkResponse",
        modelProperties: {
            success: {
                serializedName: "success",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export const TourBookingFileDtoApiResponse = {
    type: {
        name: "Composite",
        className: "TourBookingFileDtoApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                type: {
                    name: "Composite",
                    className: "TourBookingFileDto"
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const EmployeeDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "EmployeeDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EmployeeDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const ProviderCompanyDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "ProviderCompanyDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProviderCompanyDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatementsTransportDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "StatementsTransportDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "StatementsTransportDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatementsTransportDto = {
    type: {
        name: "Composite",
        className: "StatementsTransportDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            codeNumber: {
                serializedName: "codeNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimCompanyId: {
                serializedName: "claimCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            claimCompanyName: {
                serializedName: "claimCompanyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerCompanyId: {
                serializedName: "providerCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            providerCompanyName: {
                serializedName: "providerCompanyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketNumber: {
                serializedName: "ticketNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketDate: {
                serializedName: "ticketDate",
                type: {
                    name: "DateTime"
                }
            },
            route: {
                serializedName: "route",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            ticketPrice: {
                serializedName: "ticketPrice",
                type: {
                    name: "Number"
                }
            },
            vatTicket: {
                serializedName: "vatTicket",
                type: {
                    name: "Number"
                }
            },
            surcharges: {
                serializedName: "surcharges",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fine: {
                serializedName: "fine",
                type: {
                    name: "Number"
                }
            },
            amountUnusedTicket: {
                serializedName: "amountUnusedTicket",
                type: {
                    name: "Number"
                }
            },
            vatFine: {
                serializedName: "vatFine",
                type: {
                    name: "Number"
                }
            },
            serviceFee: {
                serializedName: "serviceFee",
                type: {
                    name: "Number"
                }
            },
            vatService: {
                serializedName: "vatService",
                type: {
                    name: "Number"
                }
            },
            award: {
                serializedName: "award",
                type: {
                    name: "Number"
                }
            },
            paymentOrder: {
                serializedName: "paymentOrder",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            carriageType: {
                serializedName: "carriageType",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatementsAccomodationDtoICollectionApiResponse = {
    type: {
        name: "Composite",
        className: "StatementsAccomodationDtoICollectionApiResponse",
        modelProperties: {
            contentTypes: {
                serializedName: "contentTypes",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            apiMessage: {
                serializedName: "apiMessage",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            responseCode: {
                serializedName: "responseCode",
                type: {
                    name: "Number"
                }
            },
            result: {
                serializedName: "result",
                nullable: true,
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "StatementsAccomodationDto"
                        }
                    }
                }
            },
            declaredType: {
                serializedName: "declaredType",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            statusCode: {
                serializedName: "statusCode",
                nullable: true,
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const StatementsAccomodationDto = {
    type: {
        name: "Composite",
        className: "StatementsAccomodationDto",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            claimId: {
                serializedName: "claimId",
                type: {
                    name: "Uuid"
                }
            },
            claimNumber: {
                serializedName: "claimNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            middleName: {
                serializedName: "middleName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                readOnly: true,
                nullable: true,
                type: {
                    name: "String"
                }
            },
            codeNumber: {
                serializedName: "codeNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            claimCompanyId: {
                serializedName: "claimCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            claimCompanyName: {
                serializedName: "claimCompanyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            providerCompanyId: {
                serializedName: "providerCompanyId",
                type: {
                    name: "Uuid"
                }
            },
            providerCompanyName: {
                serializedName: "providerCompanyName",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            city: {
                serializedName: "city",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            checkIn: {
                serializedName: "checkIn",
                type: {
                    name: "DateTime"
                }
            },
            checkOut: {
                serializedName: "checkOut",
                type: {
                    name: "DateTime"
                }
            },
            days: {
                serializedName: "days",
                type: {
                    name: "Number"
                }
            },
            costPerDay: {
                serializedName: "costPerDay",
                type: {
                    name: "Number"
                }
            },
            voucherNumber: {
                serializedName: "voucherNumber",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            costTotal: {
                serializedName: "costTotal",
                readOnly: true,
                type: {
                    name: "Number"
                }
            },
            invoice: {
                serializedName: "invoice",
                nullable: true,
                type: {
                    name: "String"
                }
            },
            vatAward: {
                serializedName: "vatAward",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export const Paths14B9HyfApiConversationsUploadMesageFilePostRequestbodyContentMultipartFormDataSchema = {
    type: {
        name: "Composite",
        className: "Paths14B9HyfApiConversationsUploadMesageFilePostRequestbodyContentMultipartFormDataSchema",
        modelProperties: {
            file: {
                serializedName: "file",
                type: {
                    name: "Stream"
                }
            }
        }
    }
};
