var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from "components/CircularProgress";
export default function asyncComponent(importComponent) {
    class AsyncFunc extends Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }
        componentWillMount() {
            Nprogress.start();
        }
        componentWillUnmount() {
            this.mounted = false;
        }
        componentDidMount() {
            return __awaiter(this, void 0, void 0, function* () {
                this.mounted = true;
                const { default: Component } = yield importComponent();
                Nprogress.done();
                if (this.mounted) {
                    this.setState({
                        component: _jsx(Component, Object.assign({}, this.props))
                    });
                }
            });
        }
        render() {
            const Component = this.state.component || _jsx(CircularProgress, {});
            return (_jsx(ReactPlaceholder, Object.assign({ type: "text", rows: 7, ready: Component !== null }, { children: Component })));
        }
    }
    return AsyncFunc;
}
