import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedSideNav } from "../../../appRedux/actions";
import IntlMessages from "util/IntlMessages";
const NoHeaderNotification = () => {
    const dispatch = useDispatch();
    const navCollapsed = useSelector(({ common }) => common.navCollapsed);
    return (_jsxs("div", Object.assign({ className: "gx-no-header-horizontal" }, { children: [_jsx("div", Object.assign({ className: "gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3" }, { children: _jsx("i", { className: "gx-icon-btn icon icon-menu", onClick: () => {
                        dispatch(toggleCollapsedSideNav(!navCollapsed));
                    } }) })), _jsx("div", Object.assign({ className: "gx-no-header-horizontal-top" }, { children: _jsxs("div", Object.assign({ className: "gx-no-header-horizontal-top-center" }, { children: [_jsx("i", { className: "icon icon-alert gx-mr-3" }), _jsx("p", Object.assign({ className: "gx-mb-0 gx-text-truncate" }, { children: _jsx(IntlMessages, { id: "app.announced" }) }))] })) }))] })));
};
export default NoHeaderNotification;
