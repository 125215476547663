import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { mttAuthService } from "../../services/auth/MttAuthService";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const { navStyle, themeType } = useSelector(({ settings }) => settings);
    const pathname = useSelector(({ common }) => common.pathname);
    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[0];
    return (_jsxs(_Fragment, { children: [_jsx(SidebarLogo, { sidebarCollapsed: sidebarCollapsed, setSidebarCollapsed: setSidebarCollapsed }), _jsx("div", Object.assign({ className: "gx-sidebar-content" }, { children: _jsxs(CustomScrollbars, Object.assign({ className: "gx-layout-sider-scrollbar" }, { children: [_jsxs(Menu, Object.assign({ defaultOpenKeys: [defaultOpenKeys], selectedKeys: [selectedKeys], theme: themeType === THEME_TYPE_LITE ? 'lite' : 'dark', mode: "inline" }, { children: [(mttAuthService.isSltAdmin() || mttAuthService.isSysAdmin()) &&
                                    _jsx(_Fragment, { children: _jsxs(MenuItemGroup, Object.assign({ className: "gx-menu-group", title: "Заявки" }, { children: [_jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/new-claim" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u041D\u043E\u0432\u0430\u044F \u0437\u0430\u044F\u0432\u043A\u0430" })] })) }, "new-claim"), _jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/claims" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u0410\u043A\u0442\u0438\u0432\u043D\u044B\u0435 \u0437\u0430\u044F\u0432\u043A\u0438" })] })) }, "claims"), _jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/archive-claims" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u0410\u0440\u0445\u0438\u0432" })] })) }, "archive-claims")] }), "admin-group-1") }), (mttAuthService.isSltAdmin() || mttAuthService.isSysAdmin()) &&
                                    _jsx(_Fragment, { children: _jsxs(MenuItemGroup, Object.assign({ className: "gx-menu-group", title: "Отчётные документы" }, { children: [_jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/statements-transports" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u0412\u0435\u0434\u043E\u043C\u043E\u0441\u0442\u044C - \u043F\u0440\u043E\u0435\u0437\u0434" })] })) }, "statements-transports"), _jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/statements-transports-report" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u041E\u0442\u0447\u0451\u0442 - \u043F\u0440\u043E\u0435\u0437\u0434" })] })) }, "statements-transports-report"), _jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/statements-accomodations" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u0412\u0435\u0434\u043E\u043C\u043E\u0441\u0442\u044C - \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u0438\u0435" })] })) }, "statements-accomodations"), _jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/statements-accomodations-report" }, { children: [_jsx("i", { className: "icon icon-select" }), _jsx("span", { children: "\u041E\u0442\u0447\u0451\u0442 - \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u0438\u0435" })] })) }, "statements-accomodations-report")] }), "admin-group-1") }), (mttAuthService.isSltAdmin() || mttAuthService.isSysAdmin()) &&
                                    _jsx(_Fragment, { children: _jsx(MenuItemGroup, Object.assign({ className: "gx-menu-group", title: "Компания" }, { children: _jsx(Menu.Item, { children: _jsxs(Link, Object.assign({ to: "/my-company" }, { children: [_jsx("i", { className: "icon icon-company" }), _jsx("span", { children: "\u041C\u043E\u044F \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044F" })] })) }, "my-company") }), "admin-group-1") })] })), _jsx("br", {}), _jsx("br", {})] })) }))] }));
};
export default React.memo(SidebarContent);
