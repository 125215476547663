import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';
import { useSelector } from 'react-redux';
const RequireAuth = ({ children }) => {
    const { auth } = useSelector((e) => e.settings);
    if (auth === null || auth === undefined || auth.isAuth !== true) {
        return _jsx(Redirect, { to: '/user-auth/sign-in' });
    }
    return children;
};
const App = ({ match }) => (_jsxs(Switch, { children: [_jsx(Route, { path: `${match.url}user-auth`, component: asyncComponent(() => import('./userAuth')) }), _jsx("div", Object.assign({ className: "gx-main-content-wrapper" }, { children: _jsxs(RequireAuth, { children: [_jsx(Route, { exact: true, path: `${match.url}claims`, component: asyncComponent(() => import('./claims')) }), _jsx(Route, { exact: true, path: `${match.url}statements-accomodations`, component: asyncComponent(() => import('./statementsAccomodations')) }), _jsx(Route, { exact: true, path: `${match.url}statements-accomodations-report`, component: asyncComponent(() => import('./statementsAccomodationsReport')) }), _jsx(Route, { exact: true, path: `${match.url}statements-transports-report`, component: asyncComponent(() => import('./statementsTransportsReport')) }), _jsx(Route, { exact: true, path: `${match.url}statements-transports`, component: asyncComponent(() => import('./statementsTransports')) }), _jsx(Route, { exact: true, path: `${match.url}my-company`, component: asyncComponent(() => import('./myCompany')) }), _jsx(Route, { exact: true, path: `${match.url}claim/:id?`, component: asyncComponent(() => import('./claims/claim')) }), _jsx(Route, { exact: true, path: `${match.url}new-claim`, component: asyncComponent(() => import('./newClaim')) })] }) }))] }));
export default App;
