import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";
import UserProfile from "../Sidebar/UserProfile";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
const { Header } = Layout;
const Topbar = () => {
    const { auth, locale, navStyle } = useSelector(({ settings }) => settings);
    const navCollapsed = useSelector(({ common }) => common.navCollapsed);
    const width = useSelector(({ common }) => common.width);
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();
    const languageMenu = () => (_jsx(CustomScrollbars, Object.assign({ className: "gx-popover-lang-scroll" }, { children: _jsx("ul", Object.assign({ className: "gx-sub-popover" }, { children: languageData.map(language => _jsxs("li", Object.assign({ className: "gx-media gx-pointer", onClick: () => dispatch(switchLanguage(language)) }, { children: [_jsx("i", { className: `flag flag-24 gx-mr-2 flag-${language.icon}` }), _jsx("span", Object.assign({ className: "gx-language-text" }, { children: language.name }))] }), JSON.stringify(language))) })) })));
    const updateSearchChatUser = (evt) => {
        setSearchText(evt.target.value);
    };
    return (_jsxs(_Fragment, { children: [(auth.isAuth !== true) &&
                _jsx(Header, Object.assign({ style: { background: "white", height: 100 } }, { children: _jsx("img", { alt: "logo1", src: ("/assets/images/gazprom_and_sletat.png"), style: { margin: "0 auto" } }) })), (auth.isAuth === true) &&
                _jsxs(Header, { children: [navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
                            _jsx("div", Object.assign({ className: "gx-linebar gx-mr-3" }, { children: _jsx("i", { className: "gx-icon-btn icon icon-menu", onClick: () => {
                                        dispatch(toggleCollapsedSideNav(!navCollapsed));
                                    } }) })) : null, _jsx(Link, Object.assign({ to: "/", className: "gx-d-block gx-d-lg-none gx-pointer" }, { children: _jsx("img", { alt: "", src: ("/assets/images/w-logo.png") }) })), _jsxs("ul", Object.assign({ className: "gx-header-notifications gx-ml-auto" }, { children: [width >= TAB_SIZE ? null :
                                    _jsx(Auxiliary, { children: _jsx("li", Object.assign({ className: "gx-msg" }, { children: _jsx(Popover, Object.assign({ overlayClassName: "gx-popover-horizantal", placement: "bottomRight", content: _jsx(MailNotification, {}), trigger: "click" }, { children: _jsxs("span", Object.assign({ className: "gx-pointer gx-status-pos gx-d-block" }, { children: [_jsx("i", { className: "icon icon-chat-new" }), _jsx("span", { className: "gx-status gx-status-rtl gx-small gx-orange" })] })) })) })) }), _jsx("li", Object.assign({ className: "gx-user-nav" }, { children: _jsx(Auxiliary, { children: _jsx("div", Object.assign({ style: { padding: "20px" } }, { children: _jsx(UserProfile, {}) })) }) }))] }))] })] }));
};
export default Topbar;
