import { ClientAppCreateRequest as ClientAppCreateRequestMapper, UserCreateRequest as UserCreateRequestMapper, UserPasswordResetRequest as UserPasswordResetRequestMapper, UserConfirmRequest as UserConfirmRequestMapper, UserUpdateRequest as UserUpdateRequestMapper } from "../models/mappers";
export const $host = {
    parameterPath: "$host",
    mapper: {
        serializedName: "$host",
        required: true,
        type: {
            name: "String"
        }
    },
    skipEncoding: true
};
export const accept = {
    parameterPath: "accept",
    mapper: {
        defaultValue: "application/json, text/json",
        isConstant: true,
        serializedName: "Accept",
        type: {
            name: "String"
        }
    }
};
export const contentType = {
    parameterPath: ["options", "contentType"],
    mapper: {
        defaultValue: "application/json-patch+json",
        isConstant: true,
        serializedName: "Content-Type",
        type: {
            name: "String"
        }
    }
};
export const body = {
    parameterPath: ["options", "body"],
    mapper: ClientAppCreateRequestMapper
};
export const roleName = {
    parameterPath: ["options", "roleName"],
    mapper: {
        serializedName: "roleName",
        type: {
            name: "String"
        }
    }
};
export const body1 = {
    parameterPath: ["options", "body"],
    mapper: UserCreateRequestMapper
};
export const email = {
    parameterPath: ["options", "email"],
    mapper: {
        serializedName: "email",
        type: {
            name: "String"
        }
    }
};
export const login = {
    parameterPath: ["options", "login"],
    mapper: {
        serializedName: "login",
        type: {
            name: "String"
        }
    }
};
export const newPassword = {
    parameterPath: ["options", "newPassword"],
    mapper: {
        serializedName: "newPassword",
        type: {
            name: "String"
        }
    }
};
export const email1 = {
    parameterPath: "email",
    mapper: {
        serializedName: "email",
        required: true,
        type: {
            name: "String"
        }
    }
};
export const token = {
    parameterPath: "token",
    mapper: {
        serializedName: "token",
        required: true,
        type: {
            name: "String"
        }
    }
};
export const body2 = {
    parameterPath: ["options", "body"],
    mapper: UserPasswordResetRequestMapper
};
export const login1 = {
    parameterPath: "login",
    mapper: {
        serializedName: "login",
        required: true,
        type: {
            name: "String"
        }
    }
};
export const block = {
    parameterPath: "block",
    mapper: {
        serializedName: "block",
        required: true,
        type: {
            name: "Boolean"
        }
    }
};
export const providerId = {
    parameterPath: "providerId",
    mapper: {
        serializedName: "providerId",
        required: true,
        type: {
            name: "Number"
        }
    }
};
export const body3 = {
    parameterPath: ["options", "body"],
    mapper: UserConfirmRequestMapper
};
export const take = {
    parameterPath: ["options", "take"],
    mapper: {
        serializedName: "Take",
        type: {
            name: "Number"
        }
    }
};
export const skip = {
    parameterPath: ["options", "skip"],
    mapper: {
        serializedName: "Skip",
        type: {
            name: "Number"
        }
    }
};
export const searchString = {
    parameterPath: ["options", "searchString"],
    mapper: {
        serializedName: "SearchString",
        type: {
            name: "String"
        }
    }
};
export const sortOrder = {
    parameterPath: ["options", "sortOrder"],
    mapper: {
        serializedName: "SortOrder",
        type: {
            name: "String"
        }
    }
};
export const body4 = {
    parameterPath: ["options", "body"],
    mapper: UserUpdateRequestMapper
};
export const userId = {
    parameterPath: "userId",
    mapper: {
        serializedName: "userId",
        required: true,
        type: {
            name: "String"
        }
    }
};
