import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
const SearchBox = ({ styleName, placeholder, onChange, value }) => {
    return (_jsx("div", Object.assign({ className: `gx-search-bar ${styleName}` }, { children: _jsxs("div", Object.assign({ className: "gx-form-group" }, { children: [_jsx("input", { className: "ant-input", type: "search", placeholder: placeholder, onChange: onChange, value: value }), _jsx("span", Object.assign({ className: "gx-search-icon gx-pointer" }, { children: _jsx("i", { className: "icon icon-search" }) }))] })) })));
};
export default SearchBox;
SearchBox.defaultProps = {
    styleName: "",
    value: "",
};
