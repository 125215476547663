import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import NotificationItem from "./NotificationItem";
import { notifications } from "./data";
import CustomScrollbars from 'util/CustomScrollbars';
import Auxiliary from "util/Auxiliary";
const MailNotification = () => {
    return (_jsxs(Auxiliary, { children: [_jsxs("div", Object.assign({ className: "gx-popover-header" }, { children: [_jsx("h3", Object.assign({ className: "gx-mb-0" }, { children: "Messages" })), _jsx("i", { className: "gx-icon-btn icon icon-charvlet-down" })] })), _jsx(CustomScrollbars, Object.assign({ className: "gx-popover-scroll" }, { children: _jsx("ul", Object.assign({ className: "gx-sub-popover" }, { children: notifications.map((notification, index) => _jsx(NotificationItem, { notification: notification }, index)) })) }))] }));
};
export default MailNotification;
