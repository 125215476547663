import { ClaimMessageCreateRequest as ClaimMessageCreateRequestMapper } from "../models/mappers";
export const accept = {
    parameterPath: "accept",
    mapper: {
        defaultValue: "application/json, text/json",
        isConstant: true,
        serializedName: "Accept",
        type: {
            name: "String"
        }
    }
};
export const $host = {
    parameterPath: "$host",
    mapper: {
        serializedName: "$host",
        required: true,
        type: {
            name: "String"
        }
    },
    skipEncoding: true
};
export const id = {
    parameterPath: "id",
    mapper: {
        serializedName: "id",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const companyId = {
    parameterPath: "companyId",
    mapper: {
        serializedName: "companyId",
        required: true,
        type: {
            name: "Uuid"
        }
    }
};
export const claimId = {
    parameterPath: "claimId",
    mapper: {
        serializedName: "claimId",
        required: true,
        type: {
            name: "String"
        }
    }
};
export const messageId = {
    parameterPath: "messageId",
    mapper: {
        serializedName: "messageId",
        required: true,
        type: {
            name: "String"
        }
    }
};
export const contentType = {
    parameterPath: ["options", "contentType"],
    mapper: {
        defaultValue: "application/json-patch+json",
        isConstant: true,
        serializedName: "Content-Type",
        type: {
            name: "String"
        }
    }
};
export const body = {
    parameterPath: ["options", "body"],
    mapper: ClaimMessageCreateRequestMapper
};
export const body1 = {
    parameterPath: ["options", "body"],
    mapper: {
        serializedName: "body",
        type: {
            name: "Sequence",
            element: {
                type: {
                    name: "Uuid"
                }
            }
        }
    }
};
export const messageId1 = {
    parameterPath: ["options", "messageId"],
    mapper: {
        serializedName: "messageId",
        type: {
            name: "Uuid"
        }
    },
    collectionFormat: "CSV"
};
export const statusId = {
    parameterPath: ["options", "statusId"],
    mapper: {
        serializedName: "statusId",
        type: {
            name: "Number"
        }
    },
    collectionFormat: "CSV"
};
export const contentType1 = {
    parameterPath: ["options", "contentType"],
    mapper: {
        defaultValue: "multipart/form-data",
        isConstant: true,
        serializedName: "Content-Type",
        type: {
            name: "String"
        }
    }
};
export const file = {
    parameterPath: ["options", "file"],
    mapper: {
        serializedName: "file",
        type: {
            name: "Stream"
        }
    }
};
export const accept1 = {
    parameterPath: "accept",
    mapper: {
        defaultValue: "application/json, text/json",
        isConstant: true,
        serializedName: "Accept",
        type: {
            name: "String"
        }
    }
};
