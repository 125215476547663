var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import jwtDecode from "jwt-decode";
import { accessTokenProvider } from "./Providers/AccessTokenProvider";
export class MttAuthService {
    constructor() { }
    init(mttAuthClient, accessTokenProvider, refreshTokenProvider) {
        this.mttAuthClient = mttAuthClient;
        this.accessTokenProvider = accessTokenProvider;
        this.refreshTokenProvider = refreshTokenProvider;
        return this;
    }
    get jwtTokenExpireTimeMs() {
        const convertUnixTimeToTimeStamp = (unixTime) => unixTime * 1000;
        try {
            const { exp } = jwtDecode(accessTokenProvider.getAccessToken());
            return convertUnixTimeToTimeStamp(exp) - Date.now();
        }
        catch (error) {
            return 0;
        }
    }
    initAccessTokenObserver(tokenIsNotValidHandler) {
        return __awaiter(this, void 0, void 0, function* () {
            if (window.location.pathname.startsWith('/user-auth/')) {
                return;
            }
            const refreshToken = () => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield this.authByRefreshToken();
                    yield this.initAccessTokenObserver(tokenIsNotValidHandler);
                }
                catch (e) {
                    tokenIsNotValidHandler();
                    localStorage.clear();
                    if (!window.location.pathname.startsWith('/user-auth/')) {
                        window.location.pathname = '/user-auth/sign-in';
                    }
                }
            });
            if (this.jwtTokenExpireTimeMs > 0) {
                setTimeout(() => refreshToken(), this.jwtTokenExpireTimeMs);
                return;
            }
            yield refreshToken();
        });
    }
    authByUserPassword(username, password) {
        return new Promise((resolve, reject) => {
            this.mttAuthClient
                .authFromUserPassword(username, password)
                .then((response) => {
                response.json().then((data) => {
                    this.refreshTokenProvider.setRefreshToken(data.refresh_token);
                    this.accessTokenProvider.setAccessToken(data.access_token);
                    return resolve(data);
                });
            })
                .catch((error) => reject(error));
        });
    }
    authByRefreshToken() {
        return new Promise((resolve, reject) => {
            let refreshToken = this.refreshTokenProvider.getRefreshToken();
            this.mttAuthClient
                .authByRefreshToken(refreshToken)
                .then((response) => {
                response.json().then((data) => {
                    this.refreshTokenProvider.setRefreshToken(data.refresh_token);
                    this.accessTokenProvider.setAccessToken(data.access_token);
                    return resolve(data);
                });
            })
                .catch((error) => { reject(error); });
        });
    }
    authAs(username, password, userId) {
        return new Promise((resolve, reject) => {
            this.mttAuthClient.authAs(username, password, userId)
                .then((response) => {
                response.json().then((data) => {
                    this.refreshTokenProvider.setRefreshToken(data.refresh_token);
                    this.accessTokenProvider.setAccessToken(data.access_token);
                    return resolve(data);
                });
            })
                .catch((error) => reject(error));
        });
    }
    unAuth() {
        this.refreshTokenProvider.setRefreshToken("");
        this.accessTokenProvider.setAccessToken("");
    }
    isSltAdmin() {
        var _a;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if (((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('SLT')) > -1) {
                return true;
            }
        }
        catch (_b) { }
        return false;
    }
    isSysAdmin() {
        var _a;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if (((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('SA')) > -1) {
                return true;
            }
        }
        catch (_b) { }
        return false;
    }
    isRSV() {
        var _a;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if (((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('RSV')) > -1) {
                return true;
            }
        }
        catch (_b) { }
        return false;
    }
    isTOorTOA() {
        var _a, _b;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if ((((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('TO')) > -1) || ((_b = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _b === void 0 ? void 0 : _b.indexOf('TOA')) > -1) {
                return true;
            }
        }
        catch (_c) { }
        return false;
    }
    isAudit() {
        var _a;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if (((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('AUDIT')) > -1) {
                return true;
            }
        }
        catch (_b) { }
        return false;
    }
    isTOA() {
        var _a;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if (((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('TOA')) > -1) {
                return true;
            }
        }
        catch (_b) { }
        return false;
    }
    isGROUPS_STATS_READ() {
        var _a;
        let authData = JSON.parse(localStorage.getItem('auth_data'));
        try {
            if (((_a = authData === null || authData === void 0 ? void 0 : authData.roles) === null || _a === void 0 ? void 0 : _a.indexOf('GROUPS_STATS_READ')) > -1) {
                return true;
            }
        }
        catch (_b) { }
        return false;
    }
}
export const mttAuthService = new MttAuthService();
