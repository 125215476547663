import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
export class ApiClient extends coreClient.ServiceClient {
    /**
     * Initializes a new instance of the ApiClient class.
     * @param credentials Subscription credentials which uniquely identify client subscription.
     * @param $host server parameter
     * @param options The parameter options
     */
    constructor(credentials, $host, options) {
        var _a, _b;
        if (credentials === undefined) {
            throw new Error("'credentials' cannot be null");
        }
        if ($host === undefined) {
            throw new Error("'$host' cannot be null");
        }
        // Initializing default values for options
        if (!options) {
            options = {};
        }
        const defaults = {
            requestContentType: "application/json; charset=utf-8",
            credential: credentials
        };
        const packageDetails = `azsdk-js-apiClient/1.0.0-beta.1`;
        const userAgentPrefix = options.userAgentOptions && options.userAgentOptions.userAgentPrefix
            ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
            : `${packageDetails}`;
        const optionsWithDefaults = Object.assign(Object.assign(Object.assign({}, defaults), options), { userAgentOptions: {
                userAgentPrefix
            }, baseUri: (_b = (_a = options.endpoint) !== null && _a !== void 0 ? _a : options.baseUri) !== null && _b !== void 0 ? _b : "{$host}" });
        super(optionsWithDefaults);
        // Parameter assignments
        this.$host = $host;
    }
    /** @param options The options parameters. */
    getAccomodations(options) {
        return this.sendOperationRequest({ options }, getAccomodationsOperationSpec);
    }
    /** @param options The options parameters. */
    getCarriages(options) {
        return this.sendOperationRequest({ options }, getCarriagesOperationSpec);
    }
    /** @param options The options parameters. */
    getClaims(options) {
        return this.sendOperationRequest({ options }, getClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getDetailedClaims(options) {
        return this.sendOperationRequest({ options }, getDetailedClaimsOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    getClaim(id, options) {
        return this.sendOperationRequest({ id, options }, getClaimOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    getDetailedClaim(id, options) {
        return this.sendOperationRequest({ id, options }, getDetailedClaimOperationSpec);
    }
    /**
     * @param companyId
     * @param options The options parameters.
     */
    getCompany(companyId, options) {
        return this.sendOperationRequest({ companyId, options }, getCompanyOperationSpec);
    }
    /** @param options The options parameters. */
    getCompanies(options) {
        return this.sendOperationRequest({ options }, getCompaniesOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getClaimMessages(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getClaimMessagesOperationSpec);
    }
    /**
     * @param messageId
     * @param options The options parameters.
     */
    getClaimMessage(messageId, options) {
        return this.sendOperationRequest({ messageId, options }, getClaimMessageOperationSpec);
    }
    /** @param options The options parameters. */
    createClaimMessage(options) {
        return this.sendOperationRequest({ options }, createClaimMessageOperationSpec);
    }
    /** @param options The options parameters. */
    markMessagesAsRead(options) {
        return this.sendOperationRequest({ options }, markMessagesAsReadOperationSpec);
    }
    /** @param options The options parameters. */
    setMessageStatus(options) {
        return this.sendOperationRequest({ options }, setMessageStatusOperationSpec);
    }
    /** @param options The options parameters. */
    uploadMessageFile(options) {
        return this.sendOperationRequest({ options }, uploadMessageFileOperationSpec);
    }
    /** @param options The options parameters. */
    getEmployees(options) {
        return this.sendOperationRequest({ options }, getEmployeesOperationSpec);
    }
    /** @param options The options parameters. */
    getProviderCompanies(options) {
        return this.sendOperationRequest({ options }, getProviderCompaniesOperationSpec);
    }
    /** @param options The options parameters. */
    getStatementsTransports(options) {
        return this.sendOperationRequest({ options }, getStatementsTransportsOperationSpec);
    }
    /** @param options The options parameters. */
    getStatementsAccomodations(options) {
        return this.sendOperationRequest({ options }, getStatementsAccomodationsOperationSpec);
    }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);
const getAccomodationsOperationSpec = {
    path: "/api/Accomodations/get-accomodations",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.AccomodationDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.AccomodationDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getCarriagesOperationSpec = {
    path: "/api/Carriages/get-carriages",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CarriageDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CarriageDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimsOperationSpec = {
    path: "/api/Claims/get-claims",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getDetailedClaimsOperationSpec = {
    path: "/api/Claims/get-detailed-claims",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDetailDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimOperationSpec = {
    path: "/api/Claims/get-claim/{id}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept],
    serializer
};
const getDetailedClaimOperationSpec = {
    path: "/api/Claims/get-detailed-claim/{id}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDetailDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept],
    serializer
};
const getCompanyOperationSpec = {
    path: "/api/Companies/{companyId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.CompanyDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.companyId],
    headerParameters: [Parameters.accept],
    serializer
};
const getCompaniesOperationSpec = {
    path: "/api/Companies/get-companies",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.CompanyDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimMessagesOperationSpec = {
    path: "/api/Conversations/messages/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimMessageDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimMessageDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimMessageOperationSpec = {
    path: "/api/Conversations/messages/single/{messageId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.messageId],
    headerParameters: [Parameters.accept],
    serializer
};
const createClaimMessageOperationSpec = {
    path: "/api/Conversations/messages",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        }
    },
    requestBody: Parameters.body,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: "json",
    serializer
};
const markMessagesAsReadOperationSpec = {
    path: "/api/Conversations/mark-as-read",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    requestBody: Parameters.body1,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType],
    mediaType: "json",
    serializer
};
const setMessageStatusOperationSpec = {
    path: "/api/Conversations/set-message-status",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.messageId1, Parameters.statusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const uploadMessageFileOperationSpec = {
    path: "/api/Conversations/upload-mesage-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.TourBookingFileDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.TourBookingFileDtoApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept1],
    serializer
};
const getEmployeesOperationSpec = {
    path: "/api/Employees/get-employees",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.EmployeeDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.EmployeeDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.EmployeeDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getProviderCompaniesOperationSpec = {
    path: "/api/ProviderCompanies/get-provider-companies",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderCompanyDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderCompanyDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getStatementsTransportsOperationSpec = {
    path: "/api/Statements/get-statements-transports",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.StatementsTransportDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.StatementsTransportDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.StatementsTransportDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getStatementsAccomodationsOperationSpec = {
    path: "/api/Statements/get-statements-accomodations",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.StatementsAccomodationDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.StatementsAccomodationDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.StatementsAccomodationDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
